import {Guess, State} from "../global";

const json = Array(require("./data.json"))[0] as Array<string>

function testRight(s: string, right: string): Boolean {
    for (let i = 0; i < right.length; i++) {
        let l = right.at(i)
        if (l !== ' ' && s.at(i) !== l) {
            return false
        }
    }
    return true
}

function testIgnore(s: string, ignore: Set<string>, duplicates: Set<string>): Boolean {
    var considered = new Set<string>(duplicates)
    for (let l of s) {
        var a = ignore.has(l)
        var b = considered.has(l)
        if (a && !b) {
            return false
        } else if (a && b) {
            considered.delete(l)
        }
    }
    return true
}

function testContains(s: string, contains: Map<string, Set<number>>): Boolean {
    for (let [k,v] of contains) {
        if (!s.includes(k)) {
            return false
        }else{
            for (let i of v) {
                if(s.at(i)===k){
                    return false
                }
            }
        }
    }
    return true
}

export const analizeGuess = (guesses: Guess[]) => {
    //todo: consider duplicates => dress/adiar
    //contains these letters
    var contains: Map<string, Set<number>> = new Map()

    //ignore these letters
    var ignore = new Set<string>()

    // we already know the location of the letters
    var right = "     "

    //todo: disconsider wrong location

    for (let guess of guesses) {
        for (let i = 0; i < guess.value.length; i++) {
            let letter = guess.value.at(i) as string
            if(!letter || letter ===" ") continue
            let status = guess.status.at(i)
            switch (status) {
                case State.Empty:
                    break;
                case State.Correct:
                    right = right.substring(0, i) + letter + right.substring(i + 1)
                    break;
                case State.Absent:
                    ignore.add(letter)
                    break;
                case State.Present:
                    if (!contains.hasOwnProperty(letter))
                        contains.set(letter, new Set<number>())
                    contains.get(letter)?.add(i)
                    break;
            }
        }
    }
    if (!right.includes(" ")) return [right]
    var duplicates = new Set(Array.from(contains.keys()).filter(l => ignore.has(l)))
    return json
        .map(s => s.toLowerCase())
        .filter(s => !guesses.map(g => g.value).some(g => g === s)) // ignore guesses
        .filter(s => testRight(s, right))
        .filter(s => testIgnore(s, ignore, duplicates))
        .filter(s => testContains(s, contains))
    //return Array(1000).fill("abc").map((value, index) => index % 2 == 0 ? value : "xyz")
}