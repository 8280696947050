export interface Guess {
    value: string
    status: Array<State>
}

export enum State {
    Empty = "empty",          // dunno
    Correct = "correct",      // it's right :) 
    Absent = "absent",        // it's wrong
    Present = "present"       // it's in the wrong position
}

export const nextState = (state: State): State => {
    switch (state) {
        case State.Empty:
            return State.Correct
        case State.Correct:
            return State.Absent
        case State.Absent:
            return State.Present
        case State.Present:
            return State.Empty
    }
}

export type Context = {
    guesses: Guess[]
    updateGuess: (id: number, index: number) => void
}
export {}