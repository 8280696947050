import {State} from "../global"

type Props = {
    value: string,
    status: State,
    updateGuess: () => void
}
export const Cell = ({value, status, updateGuess}: Props) => {
    return (

        <div
            className={!value || value === " " ? "empty" : status}
            style={{
                width: "7vmin",
                height: "7vmin",
                borderStyle: "solid",
                borderWidth: "2px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "2.5px 5px"
            }}
            onClick={e => {
                if (e.target === e.currentTarget) updateGuess()
            }}
        >
            <div>
                {value}
            </div>
        </div>
    )
}