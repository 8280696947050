import {Guess} from "../global";
import {Cell} from "./Cell";

type Props = {
    guess: Guess,
    updateGuess: (id: number) => void
}
export const Line = ({guess, updateGuess}: Props) => {
    return (
        <div style={{display: "flex"}}>
            {
                guess.status.map((n, i) => {
                    return (<Cell
                        value={guess.value.charAt(i)}
                        status={n}
                        updateGuess={() => updateGuess(i)}
                    />);
                })}
        </div>
    )
}