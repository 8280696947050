import './App.css';
import {Grid} from './grid/Grid';
import {CanBe} from './help/CanBe';
import Context from './context/Context';
import {Guess, nextState, State} from './global';
import {useEffect, useState} from 'react';


function App() {
    const [state, setState] = useState({
        guesses: Array(6).fill({value: "", status: Array(5).fill(State.Empty)}),
        letter: 0
    })
    const {guesses, letter} = state

    const updateGuess = (id: number, i: number) => {
        const guess = guesses[id]
        let newStatus = Array.from(guess.status)
        newStatus[i] = nextState(guess.status[i])
        setState({...state, guesses: swapGuess(id, {...guess, status: newStatus})})
    }
    const swapGuess = (i: number, g: Guess) => {
        let nGuess = [...guesses]
        nGuess[i] = g
        return nGuess
    }
    useEffect(() => {

        const modifyGuess = (id: number, index: number, char: string) => {
            const guess = guesses[id]
            return swapGuess(id, {
                value: guess.value.substring(0, index) + char + guess.value.substring(index + 1),
                status: guess.status
            })
        }
        const space = () => {
            var newLetter = Math.max(0, letter - 1)
            const id = Math.floor(newLetter / 5)
            const l = newLetter % 5
            updateGuess(id, l)
        }
        const setChar = (c: string) => {
            var newLetter = letter
            if (c === ' ') newLetter = Math.max(0, letter - 1)
            const id = Math.floor(newLetter / 5)
            const l = newLetter % 5
            const newGuesses = modifyGuess(id, l, c)
            if (c !== ' ') newLetter = Math.min(5 * 6, newLetter + 1)
            setState({
                ...state,
                guesses: newGuesses,
                letter: newLetter
            })
        }
        const listener = (e: KeyboardEvent) => {
            if (e.code === 'Backspace') {
                setChar(' ')
            } else if (e.code === 'Space') {
                e.preventDefault()
                space()
            } else {
                const key = e.key.toLowerCase()
                if (key.length === 1 && key >= 'a' && key <= 'z') {
                    setChar(key)
                }
            }
        }
        const listener2 = (e: KeyboardEvent) => {
            if (e.keyCode === 32 && e.target === document.body) {
                e.preventDefault();
            }
        }
        window.addEventListener('keydown', listener2);
        window.addEventListener('keyup', listener)
        return () => {
            window.removeEventListener('keyup', listener)
            window.removeEventListener('keyup', listener2)
        }
    }, [letter, state, guesses, swapGuess, updateGuess])

    return (
        <Context.Provider value={{
            guesses,
            updateGuess
        }}>
            <div className="App">
                <header className="App-header">
                    <Grid/>
                    <CanBe/></header>
            </div>
        </Context.Provider>
    );
}

export default App;
