import {useContext} from "react"
import Context from "../context/Context";
import {analizeGuess} from "../engine/engine";

export const CanBe = () => {
    const t = useContext(Context)
    const guesses = t?.guesses ?? []
    const alternatives = analizeGuess(guesses)
    const size = alternatives.length
    const max = 100
    return (<div className="alternatives-parent">
            <p>Possibilidades: {size}</p>
            {size > max ? (`Mostrando as ${max} primeiras`) : null}
            <div className="alternatives">
                {alternatives.slice(0, max).join(", ")}
            </div>
        </div>
    )
}