import { useContext } from "react"
import Context from "../context/Context"
import { Line } from "./line"

export const Grid = () => {
    const t = useContext(Context)

    return (
        <div>
            {t?.guesses.map((g, i) =>
                (<Line guess={g} updateGuess={id=>t?.updateGuess(i,id)} />)
            )}
        </div>
    )
}